import React from "react"
import { connect } from "react-redux"

import { getCurrentPath } from "../redux/actions"
import {
  Layout,
  Hero,
  WhyBuyFromCanada,
  FewClicksAway,
  TrustingPartners,
  WatchShortVid,
  CustomerReviews,
  ReachOutToUs,
  SEO,
  TravelPurpose,
} from "../components"

const Index = ({ location, getCurrentPath, getHealth }) => {
  React.useEffect(() => {
    getCurrentPath(location.pathname)
  })

  return (
    <Layout bkgColor={"var(--clr-neutral-200)"}>
      <SEO title="Home" />
      <Hero />
      <WhyBuyFromCanada />
      <FewClicksAway />
      <TravelPurpose />
      <TrustingPartners />
      <WatchShortVid />
      <CustomerReviews />
      <ReachOutToUs />
    </Layout>
  )
}

export default connect(null, { getCurrentPath })(Index)
